import { USER_AUTHENTICATED, USER_LOADED, USER_SIGN_OUT } from "./action-types";
import { UserListener } from "./listeners";
import { auth, db } from "../firebase";
import { googleProvider } from "./provider";

const userListener = new UserListener();

export const setAuthentication = payload => dispatch => {
    dispatch({ type: USER_AUTHENTICATED });

    userListener.on(dispatch, USER_LOADED, payload.uid);
};

export const signInProviderGoogle = () => async dispatch => {
    const { user } = await auth.signInWithPopup(googleProvider);

    const userQuery = await db
        .collection("users")
        .doc(user.uid)
        .get();

    if (userQuery.exists) {
        return dispatch(setAuthentication(unwrapUser(user)));
    }

    await db
        .collection("users")
        .doc(user.uid)
        .set(unwrapUser(user), { merge: true });

    return dispatch(setAuthentication(unwrapUser(user)));
};

export const signOut = () => async dispatch => {
    await auth.signOut();
    dispatch({
        type: USER_SIGN_OUT
    });
};

const unwrapUser = payload => ({
    uid: payload.uid,
    displayName: payload.displayName,
    photoURL: payload.photoURL,
    email: payload.email
});
