import React, { useState } from "react";

// Components
import { IconEdit } from "../icons";

export const EditInput = ({
    keyValue,
    value,
    type = "text",
    update,
    disabled = false
}) => {
    const [open, toggleOpen] = useState(false);
    const [inputValue, setValue] = useState(value);

    const onSubmit = () => {
        if (!inputValue) {
            return;
        }

        if (type === "number") {
            update({ [keyValue]: Number(inputValue) });
        } else if (type === "text") {
            update({ [keyValue]: inputValue.toLowerCase() });
        } else {
            update({ [keyValue]: inputValue });
        }
        return toggleOpen(false);
    };

    if (disabled) {
        return <div className="fs__xs fw__b"> {value}</div>;
    }

    if (open) {
        return (
            <div>
                <input
                    className="input"
                    value={inputValue}
                    type={type}
                    onChange={e => setValue(e.target.value)}
                />
                <div className="pt__s flex">
                    <button
                        className="btn btn__gray flex__2 mr__s"
                        onClick={() => toggleOpen(!open)}
                    >
                        Sluiten
                    </button>
                    <button
                        className="btn btn__green flex__2 mr__s"
                        onClick={onSubmit}
                    >
                        Opslaan
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div
            className="input__toggle flex flex__space flex__align"
            onClick={() => toggleOpen(!open)}
        >
            {value} <IconEdit className="icon icon__s c__sec" />
        </div>
    );
};
