import { GROUPS_LOADED } from "./action-types";

import { Record } from "immutable";

const GroupsState = new Record({
    list: null
});

export function groupsReducer(state = new GroupsState(), { type, payload }) {
    switch (type) {
        case GROUPS_LOADED:
            return state.setIn(["list"], payload);

        default:
            return state;
    }
}
