import { GROUP_LOADED, PAYMENTS_LOADED } from "./action-types";
import {
    DocumentListener,
    CollectionListener,
    Group,
    Payment
} from "../models";
import { db } from "../firebase";

const groupListener = new DocumentListener();
const paymentsListener = new CollectionListener();

export const groupOn = id => (dispatch, _) => {
    const query = db.collection("groups").doc(id);

    groupListener.on(query, Group, dispatch, GROUP_LOADED);
};

export const groupOff = () => () => groupListener.off();

export const paymentsOn = id => (dispatch, _) => {
    const query = db
        .collection("payments")
        .where("groupId", "==", id)
        .orderBy("created_at", "desc");

    paymentsListener.on(query, Payment, dispatch, PAYMENTS_LOADED);
};

export const paymentsOff = () => () => paymentsListener.off();
