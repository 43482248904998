import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firebase
import {
    getFormOpen,
    toggleForm
} from "../../../../../core/forms/create_payment_group";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormCreatePaymentGroup = ({ open, toggleForm }) => (
    <Modal open={open} toggle={toggleForm}>
        <Container />
    </Modal>
);

const mapStateToProps = createSelector(
    getFormOpen,
    open => ({ open })
);

const mapDispatchToProps = {
    toggleForm
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormCreatePaymentGroup);
