import React from "react";

// Components
import { datePerciseFull } from "../../utils/dates";
import { Light } from "../../light";
// s

export default ({ item }) => {
    return (
        <div className="container pt__m pb__m bb bg__w">
            <h3>{item.name}</h3>
            <div className="fs__xs c__sec text__wrap">
                <span className="fw-n">
                    Organisatie: <b>{item.organization}</b>
                </span>
                <span className="fw-n">
                    - Gestart op: <b>{datePerciseFull(item.created_at)}</b>
                </span>
            </div>

            <div className="flex flex__align fw__b fs__xs pt__s">
                <div>
                    <div className="c__sec fs__xs fw__b">zichtbaarheid:</div>
                    <div className="fw__b fs__xs flex flex__align">
                        <Light on={item.visibility} className="mr__s"></Light>
                        {item.visibility ? "actief" : "niet actief"}
                    </div>
                </div>
                <div className="ml__m pl__s bl">
                    <div className="c__sec fs__xs fw__b">
                        bedrag per gebruiker:
                    </div>

                    <div className="fw__b fs__xs">€{item.amount}</div>
                </div>
                <div className="ml__m pl__s bl">
                    <div className="c__sec fs__xs fw__b">
                        totaal gebruikers:
                    </div>

                    <div className="fw__b fs__xs">{item.users_size}</div>
                </div>
                <div className="ml__m pl__s bl">
                    <div className="c__sec fs__xs fw__b">
                        aantal betalingen:
                    </div>

                    <div className="fw__b fs__xs">{item.users_paid}</div>
                </div>
                <div className="ml__m pl__s bl">
                    <div className="c__sec fs__xs fw__b">
                        herinneringen verstuurt:
                    </div>

                    <div className="fw__b fs__xs">
                        {item.notifications_submitted}
                    </div>
                </div>
                <div className="ml__m pl__s bl">
                    <div className="c__sec fs__xs fw__b">omzet:</div>

                    <div className="fw__b fs__xs">€{item.revenue}</div>
                </div>
            </div>
        </div>
    );
};
