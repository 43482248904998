import {
    TOGGLE_FORM_CREATE_GROUP,
    TOGGLE_CREATE_GROUP_LOADING
} from "./action_types";

import { Record } from "immutable";

const FormState = new Record({
    open: false,
    loading: false
});

export function formCreatePaymentGroup(state = new FormState(), { type }) {
    switch (type) {
        case TOGGLE_FORM_CREATE_GROUP:
            return state.setIn(["open"], !state.open);

        case TOGGLE_CREATE_GROUP_LOADING:
            return state.setIn(["loading"], !state.loading);

        default:
            return state;
    }
}
