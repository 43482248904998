import React, { useState } from "react";

export const UserImage = ({ user }) => {
    const [loaded, setLoaded] = useState(false);

    if (!user.photoURL) {
        return (
            <div key="0" className="img img__s flex__center b__r bg__g">
                <span className="fs-xs fw-b">{user.displayName[0]}</span>
            </div>
        );
    }

    return (
        <div className="img bgc__g b__r">
            <img
                src={user.photoURL}
                onLoad={() => setLoaded(true)}
                className="hidden"
                alt="thumbnial"
            />
            <div
                className={`img__s b__r bg bg__loading ${loaded &&
                    "bg__loaded"}`}
                style={
                    loaded ? { backgroundImage: `url(${user.photoURL})` } : {}
                }
            />
        </div>
    );
};
