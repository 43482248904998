import { combineReducers } from "redux";
import { authenticationReducer } from "./auth";

import { formCreatePaymentGroup } from "./forms/create_payment_group";
import { formPaymentReducer } from "./forms/create_payment";
import { formPaymentEditReducer } from "./forms/edit_payment";

import { groupsReducer } from "./groups";
import { groupReducer } from "./group";
import { paymentReducer } from "./payment";
import { notificationsReducer } from "./notifications";

export const reducers = combineReducers({
    auth: authenticationReducer,

    groups: groupsReducer,
    group: groupReducer,

    form_create_group: formCreatePaymentGroup,
    form_create_payment: formPaymentReducer,
    form_edit_payment: formPaymentEditReducer,

    payment: paymentReducer,

    notifications: notificationsReducer
});
