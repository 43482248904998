import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firebase
import { groupsOn, groupsOff, getList } from "../../../core/groups";
import { toggleForm } from "../../../core/forms/create_payment_group";

// Components
import FormCreatePaymentGroup from "../../components/forms/forms/create_payment_group";
import { Card } from "../../components/cards/group";
import { Loader } from "../../components/utils/loader";
import { PageEmpty } from "../utils/page_empty";
import { ButtonRound } from "../../components/buttons/btn_round";

const Groups = ({ groupsOn, groupsOff, list, toggleForm }) => {
    useEffect(() => {
        groupsOn();
        return () => groupsOff();
    }, [groupsOn, groupsOff]);

    if (!list) {
        return (
            <div className="layout__minus_navigation flex flex__center">
                <Loader />
            </div>
        );
    }

    if (list && !list.size) {
        return (
            <div>
                <PageEmpty slug="empty_projects" />
                <FormCreatePaymentGroup />
                <ButtonRound onClick={toggleForm} />
            </div>
        );
    }

    return (
        <div>
            <div className="container row flex__space mt__m">
                {list.map(item => (
                    <Card key={item.id} item={item} />
                ))}
                <div className="col__4"></div>
            </div>
            <FormCreatePaymentGroup />
            <ButtonRound onClick={toggleForm} />
        </div>
    );
};

const mapStateToProps = createSelector(
    getList,
    list => ({ list })
);

const mapDispatchToProps = {
    groupsOn,
    groupsOff,
    toggleForm
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Groups);
