import React from "react";

// - Components
import { UserImage } from "./user_image";

export const User = ({ user, text }) =>
    user && (
        <div className="flex flex__align">
            <UserImage user={user} />
            <div className="ml__s" style={{ maxWidth: "10em" }}>
                <div className="fs__s fw__b text__wrap">{user.displayName}</div>
                <div
                    className="fs__xs c__sec fw__b relative"
                    style={{ marginTop: "-8px" }}
                >
                    {text}
                </div>
            </div>
        </div>
    );
