import { NOTIFICATIONS_LOADED } from "./action-types";
import { db } from "../firebase";
import { CollectionListener, Notification } from "../models";

const notificationsListener = new CollectionListener();

export const notificationsOn = () => (dispatch, getState) => {
    const paymentId = getState().payment.payment.id;

    const query = db
        .collection("notifications")
        .orderBy("created_at", "desc")
        .where("paymentId", "==", paymentId);

    notificationsListener.on(
        query,
        Notification,
        dispatch,
        NOTIFICATIONS_LOADED
    );
};

export const notificationsOff = () => () => notificationsListener.off();
