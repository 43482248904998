import { PAYMENT_LOADED, PAYMENT_UNLOAD, PAYMENT_ERROR } from "./actions_types";
import { db, functions } from "../firebase";
import { DocumentListener, Payment } from "../models";

const paymentListener = new DocumentListener();

export const paymentOn = (paymentId = "") => dispatch => {
    const query = db.collection("payments").doc(paymentId);

    paymentListener.on(query, Payment, dispatch, PAYMENT_LOADED, PAYMENT_ERROR);
};

export const paymentOff = () => dispatch => {
    paymentListener.off();
    dispatch({ type: PAYMENT_UNLOAD });
};

export const createPayment = () => async (dispatch, getState) => {
    const groupId = getState().payment.payment.groupId;
    const paymentId = getState().payment.payment.id;

    if (!groupId || !paymentId) return;

    const httpsCreatePayment = functions.httpsCallable("httpsCreatePayment");

    const response = await httpsCreatePayment({ groupId, paymentId })
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

    return response;
};
