import React from "react";

import { ImageLoader } from "../../components/utils/image_loader";

export const PageEmpty = ({
    message = "Er is een fout opgetreden bij het ophalen van de data",
    slug = "youlab_started"
}) => (
    <section className="layout__minus_navigation flex flex__center">
        <div className="container__wrap full center">
            <ImageLoader slug={slug} />
            <h1 className="pt__m">{message}</h1>
        </div>
    </section>
);
