import React from "react";

// - Libraries
import { useTransition, animated } from "react-spring";

// - Variables
import { ANIMATION_MODAL } from "../../../variables/animations";

// - Components
import { ModalEventHandler } from "./modal_event_handler";

export const ModalConfirm = ({ open, toggle, confirm }) => {
    const handleBackgroundClick = e => {
        if (e.target === e.currentTarget) toggle();
    };

    const transition = useTransition(open, null, ANIMATION_MODAL);

    return transition.map(
        ({ item, key, props }) =>
            item && (
                <animated.div key={key} className="modal" style={props}>
                    <div
                        className="modal__center"
                        onClick={handleBackgroundClick}
                    >
                        <div className="modal__container-m center">
                            <h1 className="c__w">
                                Weet je zeker dat je deze actie wilt uitvoeren
                            </h1>
                            <div className="flex pt__m">
                                <button
                                    className="btn btn__gray flex__2 mr__s"
                                    onClick={toggle}
                                >
                                    Terug
                                </button>
                                <button
                                    className="btn  btn__red flex__2 ml__s"
                                    onClick={confirm}
                                >
                                    Yes, ik weet het zeker
                                </button>
                            </div>
                        </div>
                    </div>
                    <ModalEventHandler toggle={toggle} />
                </animated.div>
            )
    );
};
