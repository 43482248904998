import React from "react";
import { Link } from "react-router-dom";

// Components
import { datePerciseFull } from "../utils/dates";
import { User } from "../utils/user";
import { Label } from "../lists/label";

export const Card = ({ item }) => (
    <Link
        to={`/group/${item.id}`}
        className="card col__12 col_m__6 col_l__4  mb__m"
    >
        <div>
            <h3 className="fw-b">{item.name}</h3>
            <div className="col__12">
                <div className="text__wrap full fs__xs c__sec">
                    <span className="fw-n">
                        Organisatie: <b>{item.organization}</b>
                    </span>
                    <span className="fw-n">
                        - Gestart op: <b>{datePerciseFull(item.created_at)}</b>
                    </span>
                </div>
            </div>
        </div>

        <div>
            <div className="mt__s pt__s bt">
                <User user={item.created_by} text="Beheerder" />
            </div>
            <div className="pt__s mt__s bt fs__xs flex flex__align flex__space fw__b">
                <div>
                    <Label text="Gebruikers:" />
                    <span>{item.users_size}</span>
                </div>
                <div>
                    <Label text="Betaald:" />
                    <span>{item.users_paid}</span>
                </div>
                <div>
                    <Label text="Omzet:" />
                    <span
                        className={`label ${
                            item.revenue ? "label__green" : "label__gray"
                        }`}
                    >
                        {item.revenue}€
                    </span>
                </div>
            </div>
        </div>
    </Link>
);
