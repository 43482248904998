export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const USER_LOADED = "USER_LOADED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";

// export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
// export const AUTH_LOADING = "AUTH_LOADING";

// export const AUTH_ADD_ERROR = "AUTH_ADD_ERROR";
// export const AUTH_REMOVE_ERROR = "AUTH_REMOVE_ERROR";

// export const AUTH_RESET_SUCCESS = "AUTH_RESET_SUCCESS";
