import React from "react";

// - Components
import { IconError } from "../icons";

export const ModalNavigation = ({ toggle }) => (
    <div className="modal__close flex flex__space container">
        <span />
        <button className="center c__w pt__m" onClick={toggle}>
            <IconError className="icon icon__sm" />
            <div className="fs__xs">Sluiten (Esc)</div>
        </button>
    </div>
);
