import { config } from "react-spring";

export const ANIMATION_MODAL = {
    from: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)"
    },
    enter: {
        opacity: 1,
        transform: "translate3d(0, 0%, 0)"
    },
    leave: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)"
    },
    config: config.default
};

export const ANIMATION_MODAL_SLIDE = {
    from: {
        opacity: 0,
        transform: "translate3d(100%, 0, 0)"
    },
    enter: {
        opacity: 1,
        transform: "translate3d(0%, 0, 0)"
    },
    leave: {
        opacity: 0,
        transform: "translate3d(-50%, 0, 0)"
    },
    initial: false
};

export const ANIMATION_MENU = {
    from: { opacity: 0, transform: "translate3d(0,75px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,75px,0)" },
    config: config.stiff
};
