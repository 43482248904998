import { db } from "../firebase";

export class UserListener {
    on(dispatch, action, userId) {
        const unsubscribe = db
            .collection("users")
            .doc(userId)
            .onSnapshot(payload =>
                dispatch({
                    type: action,
                    payload: payload.data()
                })
            );

        this._unsubscribe = () => unsubscribe();
    }

    off() {
        this._unsubscribe();
    }
}
