import React from "react";
import { render } from "react-dom";

import "./styles/index.scss";

import configureStore from "./core/store";
import { checkUserAuthentication } from "./core/auth";
import { Provider } from "react-redux";

import App from "./app";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = App =>
    render(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );

checkUserAuthentication(store.dispatch).then(() => renderApp(App));
