import { db } from "../firebase";
import { Group, CollectionListener } from "../models";
import { GROUPS_LOADED } from "./action-types";

const groupListener = new CollectionListener();

export const groupsOn = () => (dispatch, _) => {
    const query = db.collection("groups").orderBy("created_at", "desc");

    groupListener.on(query, Group, dispatch, GROUPS_LOADED);
};

export const groupsOff = () => () => groupListener.off();
