import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Analytics
import { analytics } from "../../../core/firebase";

// Firebase
import { groupOn, groupOff, getPaymentGroup } from "../../../core/group";
import { toggleForm } from "../../../core/forms/create_payment";

// Components
import FormCreatePayment from "../../components/forms/forms/create_payment";
import FormEditPayment from "../../components/forms/forms/edit_payment";

import Payments from "../../components/lists/payments";
import Intro from "../../components/page_components/group/intro";
import { Loader } from "../../components/utils/loader";
import { ButtonRound } from "../../components/buttons/btn_round";

const Group = ({ groupOn, groupOff, item, match: { params }, toggleForm }) => {
    useEffect(() => {
        groupOn(params.groupId);
        return () => groupOff();
    }, [groupOn, groupOff, params.groupId]);

    useEffect(() => {
        analytics.logEvent("enter_dashboard_group");
    }, []);

    return [
        Boolean(!item) && (
            <section
                key="0"
                className="layout__minus_navigation flex flex__center"
            >
                <Loader />
            </section>
        ),

        Boolean(item) && (
            <section key="1">
                <Intro item={item} />
                <Payments />
                <ButtonRound onClick={toggleForm} />
            </section>
        ),

        <FormCreatePayment key="2" />,
        <FormEditPayment key="3" />
    ];
};

const mapStateToProps = createSelector(
    getPaymentGroup,
    item => ({ item })
);

const mapDispatchToProps = {
    groupOn,
    groupOff,
    toggleForm
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Group)
);
