import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firebase
import { paymentOn, paymentOff, getPayment } from "../../../../../core/payment";
import {
    getPaymentId,
    editPayment,
    deletePayment,
    createNotification
} from "../../../../../core/forms/edit_payment";

// Variables
import { options } from "../../../../../variables/notification_options";

// Components
import { Loader } from "../../../utils/loader";
import { Label } from "../../../lists/label";
import { datePerciseFull } from "../../../utils/dates";
import { Light } from "../../../light";
import { Select } from "../../../inputs/options";
import { ModalConfirm } from "../../modal_confirm";
import { EditInput } from "../../../inputs/edit_input";
import { IconSpin } from "../../../icons";
import Logs from "./logs";

const checkFrequency = function(payload) {
    switch (payload) {
        case "NONE":
            return "Geen herinneringen";

        case "WEEKLY":
            return "Weekelijks herinneringen";

        case "MONTHLY":
            return "Maandelijks herinneringen";

        default:
            return "error in de frequency";
    }
};

const Container = ({
    paymentOn,
    paymentOff,
    payment = null,
    id,
    editPayment,
    deletePayment,
    createNotification
}) => {
    useEffect(() => {
        paymentOn(id);
        return () => paymentOff();
    }, [paymentOn, paymentOff, id]);

    const [open, toggleForm] = useState(false);
    const [loading, toggleLoading] = useState(false);

    const onChangeOption = e =>
        editPayment({ notifications_frequency: e.target.value });

    const submitNotification = async () => {
        toggleLoading(true);

        await createNotification();

        return toggleLoading(false);
    };

    if (!payment) {
        return (
            <div className="modal__slide">
                <div className="modal__container center">
                    <Loader />
                </div>
            </div>
        );
    }

    return [
        <div key="0" className="modal__slide">
            <div className="modal__container">
                <Label text="Naam:" />
                <EditInput
                    value={payment.name}
                    keyValue="name"
                    update={editPayment}
                    disabled={payment.paid}
                />

                <Label text="Email:" />
                <EditInput
                    value={payment.email}
                    keyValue="email"
                    update={editPayment}
                    disabled={payment.paid}
                />

                <Label text="Bedrag:" />
                <EditInput
                    value={payment.amount}
                    keyValue="amount"
                    update={editPayment}
                    type="number"
                    disabled={payment.paid}
                />

                <div className="flex pt__s mt__s bt fw__b">
                    <div className="flex__1">
                        <div>
                            <Label text="Bedrag:" />
                            <div className="fs__xs">{payment.amount}€</div>
                        </div>
                        <div>
                            <Label text="Link aangemaakt:" />
                            <Light on={payment.payment_link_created} />
                        </div>
                        <div>
                            <Label text="Herinneringen verstuurd:" />
                            <div className="fs__xs">
                                {payment.notifications_submitted}
                            </div>
                        </div>
                    </div>
                    <div className="flex__1">
                        <div>
                            <Label text="Betaald op:" />
                            <div className="fs__xs">
                                {payment.paid
                                    ? datePerciseFull(payment.payment_date)
                                    : "Nog niet betaald"}
                            </div>
                        </div>
                        <div>
                            <Label text="Betaald:" />
                            <Light on={payment.paid} />
                        </div>
                        <div>
                            <Label text="Herinneringen frequentie:" />
                            <div className="fs__xs">
                                {checkFrequency(
                                    payment.notifications_frequency
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt__s pt__s bt">
                    <Label text="Notificatie opties:" className="pb__xs" />
                    <Select
                        options={options}
                        onChange={onChangeOption}
                        activeOption={payment.notifications_frequency}
                    />
                </div>
                <div className="pt__s mt__s bt flex">
                    <button
                        disabled={payment.paid || loading}
                        onClick={submitNotification}
                        className={`btn flex__1 mr__s ${
                            payment.paid ? "btn__disabled" : "btn__gray"
                        }`}
                    >
                        Verstuur een herinnering
                        {loading && (
                            <IconSpin className="icon icon__s ml__s spin" />
                        )}
                    </button>
                    <button
                        className={`btn flex__1 ml__s ${
                            payment.paid ? "btn__disabled" : "btn__red"
                        }`}
                        disabled={payment.paid}
                        onClick={() => toggleForm(!open)}
                    >
                        Verwijderen
                    </button>
                </div>
                <Logs />
            </div>
        </div>,
        <ModalConfirm
            key="1"
            open={open}
            onClick={() => toggleForm(!open)}
            confirm={deletePayment}
        />
    ];
};

const mapStateToProps = createSelector(
    getPaymentId,
    getPayment,
    (id, payment) => ({ id, payment })
);

const mapDispatchToProps = {
    paymentOn,
    paymentOff,
    editPayment,
    deletePayment,
    createNotification
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);
