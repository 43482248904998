import React from "react";

import { animated } from "react-spring";
import { dateLog } from "../utils/dates";
import { Label } from "../lists/label";
import { Light } from "../light";

export const Log = ({ item, props }) => (
    <animated.div className="log flex flex__space fw__b" style={props}>
        <div className="flex__1">
            <Label text="Aangemaakt op:" />
            <div className="fs__xs">{dateLog(item.created_at)}</div>
            <Label className="pt" text="Type mail:" />
            <div className="fs__xs">{item.type}</div>
        </div>
        <div className="flex__1">
            <Label text="Mail verstuurt op:" />
            <div className="fs__xs">
                {item.submitted_at
                    ? dateLog(item.submitted_at)
                    : "Nog niet verstuurt"}
            </div>
            <Label className="pt" text="Verstuurd:" />
            <Light on={item.submitted} />
        </div>
    </animated.div>
);
