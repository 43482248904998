import React from "react";

// Components
import { IconPlusBtn } from "../icons";

export const ButtonRound = ({ onClick }) => (
    <div className="btn__fixed pb__m">
        <div className="container">
            <button className="btn__round" type="button" onClick={onClick}>
                <IconPlusBtn className="icon icon__sm" />
            </button>
        </div>
    </div>
);
