import {
    TOGGLE_FORM_PAYMENT,
    TOGGLE_FORM_PAYMENT_LOADING
} from "./action_types";

import { db } from "../../firebase";

export const toggleForm = () => (dispatch, _) =>
    dispatch({ type: TOGGLE_FORM_PAYMENT });

export const toggleLoading = () => (dispatch, _) =>
    dispatch({ type: TOGGLE_FORM_PAYMENT_LOADING });

export const submitPayment = ({ name, email }) => async (
    dispatch,
    getState
) => {
    const user = getState().auth.user.toJS();
    const groupId = getState().group.group.id;
    const amount = getState().group.group.amount;

    await db.collection("payments").add({
        name,
        email,
        amount,
        groupId,
        paid: false,
        created_at: new Date(),
        created_by: user
    });

    dispatch(toggleLoading());
    dispatch(toggleForm());
};
