import React from "react";

// - Libraries
import { useTransition, animated } from "react-spring";

// - Variables
import { ANIMATION_MODAL_SLIDE } from "../../../variables/animations";

// - Component
import { IconSpin, IconLeft, IconRight } from "../icons";

const Slide = ({
    required,
    value,
    children,
    position,
    index,
    indexUp,
    indexDown,
    submit,
    loading,
    onSubmit,
    toggle
}) => {
    const handleBackgroundClick = e => {
        if (e.target === e.currentTarget) toggle();
    };

    const open = index === position;
    const transition = useTransition(open, null, ANIMATION_MODAL_SLIDE);

    return transition.map(
        ({ item, props }) =>
            item && (
                <animated.div
                    key={index}
                    className="modal__slide"
                    style={props}
                    onClick={handleBackgroundClick}
                >
                    <div className="modal__container">
                        {children}
                        <div className="flex mt__m">
                            {!!position && (
                                <button
                                    className="btn btn__gray mr__m"
                                    onClick={indexDown}
                                >
                                    <IconLeft className="icon icon__s mr__s" />
                                    <span className="fs-s">Vorige</span>
                                </button>
                            )}
                            {required && !submit && (
                                <button
                                    disabled={!value}
                                    onClick={indexUp}
                                    className={`btn full flex__1 ${!value &&
                                        "btn__disabled"}`}
                                >
                                    <span className="fs__s">Volgende</span>
                                    <IconRight className="icon icon__s ml__s" />
                                </button>
                            )}

                            {!required && !submit && (
                                <button
                                    onClick={indexUp}
                                    className="btn full flex__1"
                                >
                                    <span className="fs__s">Volgende</span>
                                    <IconRight className="icon icon__s ml__s" />
                                </button>
                            )}
                            {submit && (
                                <button
                                    onClick={onSubmit}
                                    className="btn btn__green full flex__1"
                                    disabled={loading}
                                >
                                    <span className="fs-s">Toevoegen</span>
                                    {loading && (
                                        <IconSpin className="icon icon__s ml__s spin" />
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </animated.div>
            )
    );
};

export default Slide;
