import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firbase
import {
    getFormLoading,
    toggleForm,
    toggleLoading
} from "../../../../../core/forms/create_payment_group";

import { submitPaymentGroup } from "../../../../../core/forms/create_payment_group";

// Components
import ModalSlides from "../../modal_slides";
import ModalSlide from "../../modal_slide";
import LabelHeader from "../../../inputs/label_header";

const Container = ({
    toggleForm,
    toggleLoading,
    submitPaymentGroup,
    loading
}) => {
    const [form, setForm] = useState({
        name: "",
        organization: "",
        amount: 0
    });

    const onChange = e =>
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

    const onSubmit = () => {
        toggleLoading();

        return submitPaymentGroup(form);
    };

    return (
        <ModalSlides toggle={toggleForm}>
            <ModalSlide required value={form.name}>
                <LabelHeader text="Voeg een naam toe" />
                <input
                    className="input"
                    name="name"
                    placeholder="Type de naam hier..."
                    value={form.name}
                    onChange={onChange}
                />
            </ModalSlide>
            <ModalSlide required value={form.organization}>
                <LabelHeader text="Voeg een organisatie toe" />
                <input
                    className="input"
                    name="organization"
                    placeholder="Type de organisatie hier..."
                    value={form.organization}
                    onChange={onChange}
                />
            </ModalSlide>
            <ModalSlide required value={form.organization}>
                <LabelHeader text="Voeg een bedrag toe in Euros" />
                <input
                    className="input"
                    name="amount"
                    type="number"
                    placeholder="Bedrag in Euros"
                    value={form.amount}
                    onChange={onChange}
                />
            </ModalSlide>
            <ModalSlide submit loading={loading} onSubmit={onSubmit}>
                <LabelHeader text="Klopt alle informatie?" />
            </ModalSlide>
        </ModalSlides>
    );
};

const mapStateToProps = createSelector(
    getFormLoading,
    loading => ({ loading })
);

const mapDispatchToProps = {
    toggleForm,
    toggleLoading,
    submitPaymentGroup
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);
