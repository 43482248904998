import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";

// Db
import { paymentsOn, paymentsOff, getPayments } from "../../../../core/group";

import { toggleFormEdit } from "../../../../core/forms/edit_payment";

// Components
import { Labels } from "./labels";
import { Payment } from "./payment";
import { Loader } from "../../utils/loader";

const Payments = ({
    paymentsOn,
    paymentsOff,
    list,

    toggleFormEdit,
    match: { params }
}) => {
    useEffect(() => {
        paymentsOn(params.groupId);
        return () => {
            paymentsOff();
        };
    }, [paymentsOn, paymentsOff, params.groupId]);

    return (
        <div className="container">
            <Labels />

            {Boolean(!list) && (
                <div className="flex flex__center pt__xl pb__xl">
                    <Loader />
                </div>
            )}

            {Boolean(list && list.size) &&
                list.map(item => (
                    <Payment
                        key={item.id}
                        item={item}
                        toggleFormEdit={toggleFormEdit}
                    ></Payment>
                ))}
        </div>
    );
};

const mapStateToProps = createSelector(
    getPayments,
    list => ({ list })
);

const mapDispatchToProps = {
    paymentsOn,
    paymentsOff,
    toggleFormEdit
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Payments)
);
