import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firebase
import {
    getFormLoading,
    toggleLoading,
    toggleForm
} from "../../../../../core/forms/create_payment";

import { submitPayment } from "../../../../../core/forms/create_payment";

// Components
import ModalSlides from "../../modal_slides";
import ModalSlide from "../../modal_slide";
import LabelHeader from "../../../inputs/label_header";

const Container = ({ toggleForm, toggleLoading, loading, submitPayment }) => {
    const [form, setForm] = useState({
        name: "",
        email: ""
    });

    const onChange = e =>
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

    const onSubmit = () => {
        toggleLoading();
        return submitPayment(form);
    };
    return (
        <ModalSlides toggle={toggleForm}>
            <ModalSlide required value={form.name}>
                <LabelHeader text="Voeg een naam toe" />
                <input
                    className="input"
                    type="name"
                    name="name"
                    placeholder="Type de naam hier..."
                    value={form.name}
                    onChange={onChange}
                />
            </ModalSlide>
            <ModalSlide required value={form.email}>
                <LabelHeader text="Voeg een email adres toe" />
                <input
                    className="input"
                    name="email"
                    type="email"
                    placeholder="Type het email adres hier..."
                    value={form.email}
                    onChange={onChange}
                />
            </ModalSlide>
            <ModalSlide submit onSubmit={onSubmit} loading={loading}>
                <LabelHeader text="Klopt alle informatie?" />
            </ModalSlide>
        </ModalSlides>
    );
};

const mapStateToProps = createSelector(
    getFormLoading,
    loading => ({ loading })
);

const mapDispatchToProps = {
    toggleForm,
    toggleLoading,
    submitPayment
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);
