import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Firebase
import {
    getFormOpen,
    toggleFormEdit
} from "../../../../../core/forms/edit_payment";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormEditPayment = ({ open, toggleFormEdit }) => (
    <Modal open={open} toggle={toggleFormEdit}>
        {open && <Container />}
    </Modal>
);

const mapStateToProps = createSelector(
    getFormOpen,
    open => ({ open })
);

const mapDispatchToProps = {
    toggleFormEdit
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormEditPayment);
