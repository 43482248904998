import { auth } from "../firebase";
import { setAuthentication } from "./actions";
export * from "./reducer";
export * from "./selectors";
export * from "./actions";

export const checkUserAuthentication = dispatch =>
    new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(
            user => {
                user && dispatch(setAuthentication(user));
                unsubscribe();
                resolve();
            },
            error => reject(error)
        );
    });
