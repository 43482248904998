import React from "react";

// Components
import { ItemContainer } from "../list_container";
import { ListItem } from "../list_item";
import { datePerciseFull } from "../../utils/dates";
import { Light } from "../../light";

const checkFrequency = function(payload) {
    switch (payload) {
        case "NONE":
            return "Geen herinneringen";

        case "WEEKLY":
            return "Weekelijks herinneringen";

        case "MONTHLY":
            return "Maandelijks herinneringen";

        default:
            return "error in de frequency";
    }
};

export const Payment = ({ item, toggleFormEdit }) => (
    <ItemContainer toggleFormEdit={toggleFormEdit} paymentId={item.id}>
        <ListItem className="flex__2">{item.name}</ListItem>
        <ListItem className="flex__2">{item.email}</ListItem>
        <ListItem className="flex__1">{item.amount}€</ListItem>
        <ListItem className="flex__1">
            <Light on={item.payment_link_created} />
        </ListItem>
        <ListItem className="flex__1">
            <Light on={item.paid} />
        </ListItem>
        <ListItem className="flex__2">
            {item.payment_date
                ? datePerciseFull(item.payment_date)
                : "niet betaald"}
        </ListItem>
        <ListItem className="flex__1">
            {item.notifications_submitted ? item.notifications_submitted : 0}
        </ListItem>
        <ListItem className="flex__2">
            {checkFrequency(item.notifications_frequency)}
        </ListItem>
    </ItemContainer>
);
