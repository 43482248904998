import React from "react";
import { Svg } from "./svg";

export const Logo = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 223.25 39.33"
        {...props}
    >
        <path
            className="c-1"
            d="M24.85.69a.58.58,0,0,0-.73.48L22.94,9.28a.57.57,0,0,0,.37.63A10.41,10.41,0,0,1,30.05,19a.57.57,0,0,0,.49.53l8.12,1.19a.57.57,0,0,0,.66-.56c0-.15,0-.31,0-.46A19.68,19.68,0,0,0,24.85.69"
        />
        <path
            className="c-2"
            d="M1.17,15.21l8.11,1.18A.57.57,0,0,0,9.91,16,10.41,10.41,0,0,1,19,9.28a.58.58,0,0,0,.53-.5L20.69.67A.57.57,0,0,0,20.13,0h-.47a19.68,19.68,0,0,0-19,14.48.58.58,0,0,0,.48.73"
        />
        <path
            className="c-3"
            d="M38.16,24.12l-8.11-1.18a.57.57,0,0,0-.63.37,10.41,10.41,0,0,1-9.06,6.74.58.58,0,0,0-.53.5l-1.19,8.12a.58.58,0,0,0,.57.66h.45a19.68,19.68,0,0,0,19-14.48.58.58,0,0,0-.48-.73"
        />
        <path
            className="c-4"
            d="M16,29.42a10.41,10.41,0,0,1-6.74-9.06.58.58,0,0,0-.5-.53L.67,18.64A.57.57,0,0,0,0,19.2C0,19.35,0,37,0,37a2.32,2.32,0,0,0,2.31,2.31H14.54a.58.58,0,0,0,.57-.49l1.28-8.79a.57.57,0,0,0-.37-.63"
        />
        <path
            className="c-5"
            d="M68.8,4.66a2.89,2.89,0,0,0-4.58-3.54L54.37,13.89,44.51,1.12a2.89,2.89,0,1,0-4.58,3.54l11.55,15V36.44a2.89,2.89,0,0,0,5.78,0V19.61Z"
        />
        <path
            className="c-5"
            d="M78.66,9.25a15,15,0,1,0,15,15,15.06,15.06,0,0,0-15-15m0,24.3a9.26,9.26,0,1,1,9.25-9.26,9.27,9.27,0,0,1-9.25,9.26"
        />
        <path
            className="c-5"
            d="M154.43,33.55H136.5V3.47a2.9,2.9,0,0,0-5.79,0v33a2.89,2.89,0,0,0,2.9,2.89h20.82a2.89,2.89,0,0,0,0-5.78"
        />
        <path
            className="c-5"
            d="M172.36,9.25a15,15,0,1,0,9.25,26.89v.3a2.9,2.9,0,0,0,5.79,0V24.29a15.06,15.06,0,0,0-15-15m0,24.3a9.26,9.26,0,1,1,9.25-9.26,9.27,9.27,0,0,1-9.25,9.26"
        />
        <path
            className="c-5"
            d="M208.22,9.25a15,15,0,0,0-9.26,3.2v-9a2.89,2.89,0,0,0-5.78,0v33a2.89,2.89,0,0,0,5.78,0v-.3a15,15,0,1,0,9.26-26.89m0,24.3a9.26,9.26,0,1,1,9.25-9.26,9.27,9.27,0,0,1-9.25,9.26"
        />
        <path
            className="c-5"
            d="M122,9.25a2.89,2.89,0,0,0-2.89,2.9V26a7.52,7.52,0,0,1-15,0V12.15a2.9,2.9,0,1,0-5.79,0V26A13.29,13.29,0,0,0,119.19,37a2.89,2.89,0,0,0,5.74-.52V12.15A2.89,2.89,0,0,0,122,9.25"
        />
    </svg>
);

export const IconOpen = props => (
    <Svg {...props}>
        <polyline points="6 9 12 15 18 9" />
    </Svg>
);

export const IconHome = props => (
    <Svg {...props}>
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
        <polyline points="9 22 9 12 15 12 15 22" />
    </Svg>
);

export const IconList = props => (
    <Svg {...props}>
        <line x1="21" y1="10" x2="3" y2="10" />
        <line x1="21" y1="6" x2="3" y2="6" />
        <line x1="21" y1="14" x2="3" y2="14" />
        <line x1="21" y1="18" x2="3" y2="18" />
    </Svg>
);

export const IconSmile = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="10" />
        <path d="M8 14s1.5 2 4 2 4-2 4-2" />
        <line x1="9" y1="9" x2="9.01" y2="9" />
        <line x1="15" y1="9" x2="15.01" y2="9" />
    </Svg>
);

export const IconSpin = props => (
    <Svg {...props}>
        <line x1="12" y1="2" x2="12" y2="6" />
        <line x1="12" y1="18" x2="12" y2="22" />
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
        <line x1="2" y1="12" x2="6" y2="12" />
        <line x1="18" y1="12" x2="22" y2="12" />
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
    </Svg>
);

export const IconArchive = props => (
    <Svg {...props}>
        <polyline points="21 8 21 21 3 21 3 8" />
        <rect x="1" y="3" width="22" height="5" />
        <line x1="10" y1="12" x2="14" y2="12" />
    </Svg>
);

export const IconAlarm = props => (
    <Svg {...props}>
        <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0" />
    </Svg>
);

export const IconAttachment = props => (
    <Svg {...props}>
        <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
    </Svg>
);

export const IconAward = props => (
    <Svg {...props}>
        <circle cx="12" cy="8" r="7" fill="#ffdd59" />
        <polyline
            points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"
            fill="#ffc048"
        />
    </Svg>
);

export const IconView = props => (
    <Svg {...props}>
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
        <circle cx="12" cy="12" r="3" />
    </Svg>
);

export const IconEnter = props => (
    <Svg {...props}>
        <polyline points="9 10 4 15 9 20" />
        <path d="M20 4v7a4 4 0 0 1-4 4H4" />
    </Svg>
);

export const IconUser = props => (
    <Svg {...props}>
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
    </Svg>
);

export const IconImage = props => (
    <Svg {...props}>
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <polyline points="21 15 16 10 5 21" />
    </Svg>
);

export const IconUp = props => (
    <Svg {...props}>
        <line x1="12" y1="19" x2="12" y2="5" />
        <polyline points="5 12 12 5 19 12" />
    </Svg>
);

export const IconLevel = props => (
    <Svg {...props}>
        <line x1="5" y1="12" x2="19" y2="12" />
    </Svg>
);

export const IconDown = props => (
    <Svg {...props}>
        <line x1="12" y1="5" x2="12" y2="19" />
        <polyline points="19 12 12 19 5 12" />
    </Svg>
);

export const IconStatus = props => (
    <Svg {...props}>
        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
    </Svg>
);

export const IconLink = props => (
    <Svg {...props}>
        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
        <polyline points="15 3 21 3 21 9" />
        <line x1="10" y1="14" x2="21" y2="3" />
    </Svg>
);
export const IconPlus = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="16" />
        <line x1="8" y1="12" x2="16" y2="12" />
    </Svg>
);

export const IconError = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="10" />
        <line x1="15" y1="9" x2="9" y2="15" />
        <line x1="9" y1="9" x2="15" y2="15" />
    </Svg>
);

export const IconHelp = props => (
    <Svg {...props}>
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />{" "}
        <circle cx="12" cy="12" r="10" />{" "}
        <line x1="12" y1="17" x2="12" y2="17" />
    </Svg>
);

export const IconCheck = props => (
    <Svg {...props}>
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
        <polyline points="22 4 12 14.01 9 11.01" />
    </Svg>
);

export const IconEdit = props => (
    <Svg {...props}>
        <polygon points="16 3 21 8 8 21 3 21 3 16 16 3" />
    </Svg>
);

export const IconMessage = props => (
    <Svg {...props}>
        <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
    </Svg>
);

export const IconInbox = props => (
    <Svg {...props}>
        <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
        <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
    </Svg>
);

export const IconPlay = props => (
    <Svg {...props}>
        <polygon points="5 3 19 12 5 21 5 3" />
    </Svg>
);

export const IconDownload = props => (
    <Svg {...props}>
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
    </Svg>
);

export const IconLogin = props => (
    <Svg {...props}>
        <path d="M14 22h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-5" />
        <polyline points="11 16 15 12 11 8" />
        <line x1="15" y1="12" x2="3" y2="12" />
    </Svg>
);

export const IconRight = props => (
    <Svg {...props}>
        <polyline points="9 18 15 12 9 6" />
    </Svg>
);

export const IconLeft = props => (
    <Svg {...props}>
        <polyline points="15 18 9 12 15 6" />
    </Svg>
);

export const IconFile = props => (
    <Svg {...props}>
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
        <polyline points="14 2 14 8 20 8" />
    </Svg>
);

export const IconTrash = props => (
    <Svg {...props}>
        <polyline points="3 6 5 6 21 6" />
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
        <line x1="10" y1="11" x2="10" y2="17" />
        <line x1="14" y1="11" x2="14" y2="17" />
    </Svg>
);

export const IconCredits = props => (
    <Svg {...props}>
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </Svg>
);

export const IconPlusBtn = props => (
    <Svg {...props}>
        <line x1="12" y1="5" x2="12" y2="19"></line>{" "}
        <line x1="5" y1="12" x2="19" y2="12"></line>{" "}
    </Svg>
);

export const IconFilter = props => (
    <Svg {...props}>
        <line x1="4" y1="21" x2="4" y2="14" />
        <line x1="4" y1="10" x2="4" y2="3" />
        <line x1="12" y1="21" x2="12" y2="12" />
        <line x1="12" y1="8" x2="12" y2="3" />
        <line x1="20" y1="21" x2="20" y2="16" />
        <line x1="20" y1="12" x2="20" y2="3" />
        <line x1="1" y1="14" x2="7" y2="14" />
        <line x1="9" y1="8" x2="15" y2="8" />
        <line x1="17" y1="16" x2="23" y2="16" />
    </Svg>
);

export const IconAlert = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12" y2="16" />
    </Svg>
);

export const IconGrid = props => (
    <Svg {...props}>
        <rect x="3" y="3" width="7" height="7" />
        <rect x="14" y="3" width="7" height="7" />
        <rect x="14" y="14" width="7" height="7" />
        <rect x="3" y="14" width="7" height="7" />
    </Svg>
);

export const IconSettings = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="1" />
        <circle cx="19" cy="12" r="1" />
        <circle cx="5" cy="12" r="1" />
    </Svg>
);

export const IconDate = props => (
    <Svg {...props}>
        <circle cx="12" cy="12" r="10" />
        <polyline points="12 6 12 12 15 15" />
    </Svg>
);

export const IconThumbsUp = props => (
    <Svg {...props}>
        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
    </Svg>
);
export const IconThumbsDown = props => (
    <Svg {...props}>
        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
    </Svg>
);

export const IconGoogle = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 139.72 141.54"
        {...props}
    >
        <defs>
            <clipPath id="clip-path">
                <path
                    className="cls-1"
                    d="M114.44,71.8a52,52,0,0,0-.83-9.3H70.77V80.09H95.25a20.88,20.88,0,0,1-9.08,13.73v11.42h14.71c8.6-7.92,13.56-19.58,13.56-33.44Z"
                />
            </clipPath>
            <clipPath id="clip-path-2">
                <path
                    className="cls-1"
                    d="M70.77,116.26c12.28,0,22.58-4.07,30.11-11L86.17,93.82A27.43,27.43,0,0,1,45.31,79.41H30.11V91.2a45.48,45.48,0,0,0,40.66,25.06Z"
                />
            </clipPath>
            <clipPath id="clip-path-3">
                <path
                    className="cls-1"
                    d="M45.31,79.41a27,27,0,0,1,0-17.29V50.34H30.11a45.54,45.54,0,0,0,0,40.86l15.2-11.79Z"
                />
            </clipPath>
            <clipPath id="clip-path-4">
                <path
                    className="cls-1"
                    d="M70.77,43.37a24.57,24.57,0,0,1,17.39,6.8l13-13.05A43.74,43.74,0,0,0,70.77,25.27,45.49,45.49,0,0,0,30.11,50.34l15.2,11.78A27.12,27.12,0,0,1,70.77,43.37Z"
                />
            </clipPath>
        </defs>

        <g className="cls-2">
            <rect
                id="_Path_"
                data-name="&lt;Path&gt;"
                className="cls-3"
                x="45.49"
                y="37.22"
                width="94.22"
                height="93.29"
            />
        </g>
        <g className="cls-4">
            <rect
                id="_Path_2"
                data-name="&lt;Path&gt;"
                className="cls-5"
                x="4.84"
                y="54.14"
                width="121.31"
                height="87.4"
            />
        </g>
        <g className="cls-6">
            <rect
                id="_Path_3"
                data-name="&lt;Path&gt;"
                className="cls-7"
                y="25.06"
                width="70.59"
                height="91.41"
            />
        </g>
        <g className="cls-8">
            <rect
                id="_Path_4"
                data-name="&lt;Path&gt;"
                className="cls-9"
                x="4.84"
                width="121.64"
                height="87.4"
            />
        </g>
    </svg>
);

export const IconFacebook = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.09 84.9" {...props}>
        <path
            className="c-f"
            d="M13,84.9V46.17H0V31.08H13V20C13,7,20.92,0,32.44,0A108.43,108.43,0,0,1,44.09.59v13.5h-8c-6.27,0-7.48,3-7.48,7.34v9.64H43.56L41.62,46.17h-13V84.9Z"
        />
    </svg>
);

export const IconStar = props => (
    <Svg {...props}>
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </Svg>
);
