const months = [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december"
];

export const datePercise = payload => {
    const date = payload.toDate();

    if (date.getTime()) {
        const day = date.getDate();
        const month = date.getMonth();

        return `${day} ${months[month]}`;
    }

    return `geen datum`;
};

export const datePerciseFull = payload => {
    const date = payload.toDate();

    if (date.getTime()) {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${months[month]} ${year}`;
    }

    return "geen datum";
};

export const dateLog = payload => {
    const date = payload.toDate();

    if (date.getTime()) {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const time = date.toLocaleTimeString();

        return `${time} - ${day} ${months[month]} ${year}`;
    }

    return "geen datum";
};
