import { PAYMENT_LOADED, PAYMENT_ERROR, PAYMENT_UNLOAD } from "./actions_types";
import { Record } from "immutable";

export const PaymentState = new Record({
    payment: null,
    error: false
});

export function paymentReducer(state = new PaymentState(), { type, payload }) {
    switch (type) {
        case PAYMENT_LOADED:
            return state.setIn(["payment"], payload);

        case PAYMENT_ERROR:
            return state.setIn(["error"], true);

        case PAYMENT_UNLOAD:
            return new PaymentState();
        default:
            return state;
    }
}
