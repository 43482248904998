import {
    TOGGLE_FORM_CREATE_GROUP,
    TOGGLE_CREATE_GROUP_LOADING
} from "./action_types";

import { db } from "../../firebase";

export const toggleForm = () => dispatch =>
    dispatch({ type: TOGGLE_FORM_CREATE_GROUP });

export const toggleLoading = () => dispatch =>
    dispatch({ type: TOGGLE_CREATE_GROUP_LOADING });

export const submitPaymentGroup = ({ name, organization, amount }) => async (
    dispatch,
    getState
) => {
    const user = getState().auth.user.toJS();

    const result = await db
        .collection("groups")
        .add({
            name,
            organization,
            amount: Number(amount),
            usersPaid: 0,
            usersOutstanding: 0,
            notificationsSubmitted: 0,
            visibility: true,
            created_by: user,
            created_at: new Date()
        })
        .catch(error => ({ error }));

    if (result.error) {
        // do some loggin here
        return dispatch(toggleLoading());
    }

    dispatch(toggleLoading());
    return dispatch(toggleForm());
};
