import React from "react";

import { withRouter, Switch, Route } from "react-router-dom";

// Variables
import { routes } from "../variables/routes_authentication";

// Components
import SignIn from "../views/pages/sign_in";
import Root from "../views/pages/root";
import Payment from "../views/pages/payment";

const App = () => (
    <Switch>
        <Route exact path={routes.ROOT} component={Root} />
        <Route path={routes.SIGN_IN} component={SignIn} />
        <Route path={routes.PAYMENT} component={Payment} />
        <Route component={Root} />
    </Switch>
);

export default withRouter(App);
