export const config = {
    apiKey: "AIzaSyDNUZD8FzAwx4KkUIEBGvACu8yYgx5KDq8",
    authDomain: "youlab-betaal-app.firebaseapp.com",
    databaseURL: "https://youlab-betaal-app.firebaseio.com",
    projectId: "youlab-betaal-app",
    storageBucket: "youlab-betaal-app.appspot.com",
    messagingSenderId: "821153168061",
    appId: "1:821153168061:web:c9a1b50b4cd30ffd",
    measurementId: "G-ZT0X232DGV"
};
