import {
    TOGGLE_FORM_PAYMENT,
    TOGGLE_FORM_PAYMENT_LOADING
} from "./action_types";

import { Record } from "immutable";

const FormState = new Record({
    open: false,
    loading: false
});

export function formPaymentReducer(state = new FormState(), { type }) {
    switch (type) {
        case TOGGLE_FORM_PAYMENT:
            return state.setIn(["open"], !state.open);

        case TOGGLE_FORM_PAYMENT_LOADING:
            return state.setIn(["loading"], !state.loading);

        default:
            return state;
    }
}
