import React from "react";

const Svg = ({ className, viewBox, children }) => (
    <svg
        className={className}
        viewBox={viewBox ? viewBox : "0 0 24 24"}
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
    >
        <g strokeLinecap="round" strokeLinejoin="round">
            {children}
        </g>
    </svg>
);

export { Svg };
