import React from "react";

// Components
import { LabelContainer } from "../label_container";
import { Label } from "../label";

export const Labels = () => (
    <LabelContainer>
        <Label className="flex__2 pr__s text__wrap" text="Naam" />
        <Label className="flex__2 pr__s text__wrap" text="Email adres" />
        <Label className="flex__1 pr__s text__wrap" text="Bedrag" />
        <Label className="flex__1 pr__s text__wrap" text="Link" />
        <Label className="flex__1 pr__s text__wrap" text="Betaald" />
        <Label className="flex__2 pr__s text__wrap" text="Datum" />
        <Label className="flex__1 pr__s text__wrap" text="Herinneringen" />
        <Label className="flex__2 pr__s text__wrap" text="Frequentie" />
    </LabelContainer>
);
