import React from "react";

// - Libraries
import { useTransition, animated } from "react-spring";

// - Variables
import { ANIMATION_MODAL } from "../../../variables/animations";

// - Components
import { ModalEventHandler } from "./modal_event_handler";
import { ModalNavigation } from "./modal_navigation";

export default ({ open, children, toggle, center }) => {
    const handleBackgroundClick = e => {
        if (e.target === e.currentTarget) toggle();
    };

    const transition = useTransition(open, null, ANIMATION_MODAL);

    return transition.map(
        ({ item, key, props }) =>
            item && (
                <animated.div key={key} className="modal" style={props}>
                    <ModalNavigation toggle={toggle} />
                    <div
                        className={center ? "modal__center" : "modal__scroll"}
                        onClick={handleBackgroundClick}
                    >
                        {children}
                    </div>
                    <ModalEventHandler toggle={toggle} />
                </animated.div>
            )
    );
};
