import React from "react";

export const Select = ({ options = [], activeOption, onChange }) => {
    return (
        <select className="input" onChange={onChange} value={activeOption}>
            {options.map((item, index) => (
                <option key={index} value={item}>
                    {item}
                </option>
            ))}
        </select>
    );
};
