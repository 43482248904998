import {
    TOGGLE_FORM_PAYMENT_EDIT,
    TOGGLE_FORM_PAYMENT_EDIT_LOADING
} from "./action_types";
import { db, functions } from "../../firebase";

export const toggleFormEdit = (payload = null) => dispatch =>
    dispatch({ type: TOGGLE_FORM_PAYMENT_EDIT, payload });

export const toggleLoading = () => dispatch =>
    dispatch({ type: TOGGLE_FORM_PAYMENT_EDIT_LOADING });

export const editPayment = payload => async (dispatch, getState) => {
    const id = getState().payment.payment.id;

    if (!id) return;

    await db
        .collection("payments")
        .doc(id)
        .update({
            ...payload
        });

    return dispatch(toggleLoading());
};

export const deletePayment = () => async (dispatch, getState) => {
    const id = getState().payment.payment.id;

    if (!id) return;

    await db
        .collection("payments")
        .doc(id)
        .delete();

    dispatch(toggleLoading());
    return dispatch(toggleFormEdit());
};

export const createNotification = () => async (dispatch, getState) => {
    const payment = getState().payment.payment.toJS();

    if (!payment) return;

    const httpsCreateNotification = functions.httpsCallable(
        "httpsCreateNotification"
    );

    const response = await httpsCreateNotification({ ...payment })
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

    return response;
};
