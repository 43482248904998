import React, { useState } from "react";

export const ImageLoader = ({ slug, className = "" }) => {
    const [loaded, setLoaded] = useState(false);

    return [
        <img
            key="1"
            src={`${process.env.PUBLIC_URL}/illustrations/${slug}.svg`}
            onLoad={() => setLoaded(true)}
            className="hidden"
            alt="loader"
        />,
        <img
            key="2"
            src={`${process.env.PUBLIC_URL}/illustrations/${slug}.svg`}
            className={`bg__loading ${className} ${loaded && "bg__loaded"}`}
            alt="loader"
        />
    ];
};
