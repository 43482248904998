import { NOTIFICATIONS_LOADED } from "./action-types";
import { PAYMENT_UNLOAD } from "../payment/actions_types";

import { Record } from "immutable";

const NotificationState = new Record({
    list: null
});

export function notificationsReducer(
    state = new NotificationState(),
    { type, payload }
) {
    switch (type) {
        case NOTIFICATIONS_LOADED:
            return state.setIn(["list"], payload);

        case PAYMENT_UNLOAD:
            return state.setIn(["list"], null);

        default:
            return state;
    }
}
