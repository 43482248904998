import React, { useEffect } from "react";

import { withRouter, Switch, Route } from "react-router-dom";

// Analytics
import { analytics } from "../../../core/firebase";

// Variables
import { routes } from "../../../variables/routes_authenticated";

// Components
import { Navigation } from "../../components/navigations/main_navigation";
import PageGroups from "./groups";
import PageGroup from "./group";

export default withRouter(({ history: { replace } }) => {
    useEffect(() => {
        return () => {
            replace(routes.ROOT);
        };
    }, [replace]);

    useEffect(() => {
        analytics.logEvent("enter_dashboard");
    }, []);

    return (
        <main>
            <Navigation />
            <Switch>
                <Route exact path={routes.ROOT} component={PageGroups} />
                <Route path={routes.GROUP} component={PageGroup} />
                <Route component={PageGroups} />
            </Switch>
        </main>
    );
});
