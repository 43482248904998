import { GROUP_LOADED, PAYMENTS_LOADED } from "./action-types";
import { Record } from "immutable";

const GroupState = new Record({
    payments: null,
    group: null
});

export function groupReducer(state = new GroupState(), { type, payload }) {
    switch (type) {
        case GROUP_LOADED:
            return state.setIn(["group"], payload);

        case PAYMENTS_LOADED:
            return state.setIn(["payments"], payload);

        default:
            return state;
    }
}
