import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

// Varibles
import { routes } from "../../../variables/routes_authentication";

// Components
import { Navigation } from "../../components/navigations/plain";
import { ImageLoader } from "../../components/utils/image_loader";

export default withRouter(({ history: { replace } }) => {
    useEffect(() => {
        replace(routes.ROOT);
    }, [replace]);

    return (
        <main>
            <Navigation></Navigation>
            <section className="layout__minus_navigation flex container">
                <div className="full flex flex__1 flex__align">
                    <div className="">
                        <h3 className="fw__n center">
                            We hebben de pagina niet kunnen vinden, weet je
                            zeker dat je de juiste link hebt?
                        </h3>
                        <a
                            href="https://app.youlab.nl"
                            className="btn mt__m full"
                        >
                            Terug naar YouLab
                        </a>
                    </div>
                </div>
                <div className="flex flex__align flex__2 full">
                    <ImageLoader slug="empty_projects" className="full" />
                </div>
            </section>
        </main>
    );
});
