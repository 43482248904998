import React from "react";

// Libraries
import { connect } from "react-redux";

// Firebase;
import { signInProviderGoogle } from "../../../core/auth";

// - Components
import { IconGoogle } from "../../components/icons";

const Social = ({ signInProviderGoogle }) => (
    <div className="pt__m">
        <button
            className="btn btn__border full"
            onClick={signInProviderGoogle}
            type="button"
        >
            <span className="fs__s mr__s">Aanmelden via Google</span>
            <IconGoogle className="icon icon__sm" />
        </button>
    </div>
);

const mapDispatchToProps = {
    signInProviderGoogle
};

export default connect(
    null,
    mapDispatchToProps
)(Social);
