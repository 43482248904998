import React, { useEffect } from "react";

import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
    notificationsOn,
    notificationsOff,
    getNotifications
} from "../../../../../core/notifications";

// Components
import { Log } from "../../../log";
import { Loader } from "../../../utils/loader";

const Logs = ({ notificationsOn, notificationsOff, list }) => {
    useEffect(() => {
        notificationsOn();
        return () => notificationsOff();
    }, [notificationsOn, notificationsOff]);

    if (!list) {
        return (
            <div className="pt__m mt__m center bt">
                <Loader />
            </div>
        );
    }

    return (
        <div className="log__container">
            {list.size ? (
                list.map(item => <Log key={item.id} item={item} />)
            ) : (
                <div className="fs--s fw-b pt pb">Nog geen notificaties</div>
            )}
        </div>
    );
};

const mapStateToProps = createSelector(
    getNotifications,
    list => ({ list })
);

const mapDispatchToProps = {
    notificationsOn,
    notificationsOff
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logs);
