import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

// Firebase
import { signOut } from "../../../core/auth";

// Variables
import { routes } from "../../../variables/routes_authenticated";

//  Components
import { Logo, IconLogin } from "../icons";

const MainNav = ({ signOut }) => {
    return (
        <nav className="navigation">
            <div className="container flex flex__align flex__space navigation__height">
                <NavLink to={routes.ROOT} exact>
                    <Logo className="logo" />
                </NavLink>
                <button className="btn" onClick={signOut}>
                    Afmelden
                    <IconLogin className="icon icon__s ml__s" />
                </button>
            </div>
        </nav>
    );
};

const mapDispatchToProps = {
    signOut
};

export const Navigation = connect(
    null,
    mapDispatchToProps
)(MainNav);
