import React from "react";

import { Logo } from "../icons";

export const Navigation = () => (
    <nav className="navigation">
        <div className="navigation__height container flex flex__align">
            <Logo className="logo" />
        </div>
    </nav>
);
