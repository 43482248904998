import {
    TOGGLE_FORM_PAYMENT_EDIT,
    TOGGLE_FORM_PAYMENT_EDIT_LOADING
} from "./action_types";

import { Record } from "immutable";

const FormState = new Record({
    open: false,
    loading: false,
    paymentId: null
});

export function formPaymentEditReducer(
    state = new FormState(),
    { type, payload }
) {
    switch (type) {
        case TOGGLE_FORM_PAYMENT_EDIT:
            return state.merge({
                open: !state.open,
                paymentId: payload
            });

        case TOGGLE_FORM_PAYMENT_EDIT_LOADING:
            return state.setIn(["loading"], !state.laoding);

        default:
            return state;
    }
}
