import React from "react";

import { Loader } from "../../components/utils/loader";
import { Logo } from "../../components/icons";

export default () => (
    <div className="layout__full flex flex__center center container">
        <div>
            <Logo className="logo__m mb__m" />
            <div />
            <Loader />
        </div>
    </div>
);
