// - Libraries
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";

// - Variables
import { config } from "./config";

const firebaseApp = firebase.initializeApp(config);

export const analytics = firebaseApp.analytics();
export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
export const functions = firebaseApp.functions("europe-west1");
