import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";

// Firebase
import {
    getPayment,
    getError,
    paymentOn,
    paymentOff,
    createPayment
} from "../../../core/payment";

import { analytics } from "../../../core/firebase";

// Components
import PageLoading from "../utils/page_loading";
import { PageError } from "../utils/page_error";
import { Navigation } from "../../components/navigations/plain";
import { ImageLoader } from "../../components/utils/image_loader";
import { Light } from "../../components/light";
import { Label } from "../../components/lists/label";
import { datePerciseFull } from "../../components/utils/dates";
import { IconSpin } from "../../components/icons";

const Payment = ({
    match: { params },
    payment,
    error,
    paymentOn,
    paymentOff,
    createPayment
}) => {
    useEffect(() => {
        paymentOn(params.paymentId);
        return () => paymentOff();
    }, [paymentOn, paymentOff, params.paymentId]);

    useEffect(() => {
        analytics.logEvent("payment_visit_page", { id: params.paymentId });
    }, [params.paymentId]);

    const [loading, setLoading] = useState(false);

    const submitPayment = async () => {
        analytics.logEvent("payment_create_link");
        setLoading(true);
        const { payload = {} } = await createPayment();

        if (Boolean(payload.data.error)) {
            analytics.logEvent("payment_error_creating_link");
        } else {
            analytics.logEvent("payment_success_creating_link");
        }
        setLoading(false);
    };

    const openPaymentLink = () => {
        analytics.logEvent("payment_open_payment_link");
    };

    if (error) {
        analytics.logEvent("payment_error_getting_payment", {
            id: params.paymentId || "undefined"
        });
        return (
            <PageError
                message={
                    "We hebben jou betaallink helaas niet kunnen vinden. Gebruik svp de link die in uw email is verstuurt of neem contact op met de ontwikkelaars."
                }
            />
        );
    }

    if (!payment) {
        return <PageLoading />;
    }

    return (
        <main>
            <Navigation />
            <section className="layout__minus_navigation row flex__reverse container">
                <div className="col__12 col_m__8 flex flex__center">
                    {payment.paid ? (
                        <ImageLoader
                            slug="betaling gedaan"
                            className="img__payment"
                        />
                    ) : (
                        <ImageLoader
                            slug="betaling niet gedaan"
                            className="img__payment"
                        />
                    )}
                </div>
                <div className="col__12 col_m__4 flex flex__align">
                    <div className="card">
                        <div className="fw__b">
                            {payment.name}
                            {payment.paid && (
                                <span className="c__sec ml__s">(betaald)</span>
                            )}
                        </div>
                        <div className="flex pt__s mt__s bt fw__b">
                            <div className="flex__1">
                                <div className="pb__s">
                                    <Label text="Bedrag:" />
                                    <div className="fs__xs">
                                        {payment.amount}€
                                    </div>
                                </div>
                                <div>
                                    <Label text="Link aangemaakt:" />
                                    <Light on={payment.payment_link_created} />
                                </div>
                            </div>
                            <div className="flex__1">
                                <div>
                                    <Label text="Betaald op:" />
                                    <div className="fs__xs">
                                        {payment.paid
                                            ? datePerciseFull(
                                                  payment.payment_date
                                              )
                                            : "Nog niet betaald"}
                                    </div>
                                </div>
                                <div className="pt__s">
                                    <Label text="Betaald:" />
                                    <Light on={payment.paid} />
                                </div>
                            </div>
                        </div>
                        {payment.paid && (
                            <div className="pt__s mt__s bt fw__b center">
                                Bedankt voor jouw betaling!
                            </div>
                        )}
                        <div className="pt__s mt__s bt">
                            {Boolean(
                                !payment.payment_link_created ||
                                    !payment.payment_url
                            ) && (
                                <button
                                    className="btn btn__border full"
                                    disabled={loading}
                                    onClick={() => submitPayment()}
                                >
                                    {loading
                                        ? "Loading..."
                                        : "Betaal link aanmaken"}
                                    {loading && (
                                        <IconSpin className="icon icon__s spin" />
                                    )}
                                </button>
                            )}
                            {Boolean(
                                payment.payment_link_created &&
                                    payment.payment_url &&
                                    !payment.paid
                            ) && (
                                <a
                                    className="btn btn__green full"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={openPaymentLink}
                                    href={payment.payment_url}
                                >
                                    Betaling maken
                                </a>
                            )}
                            {Boolean(payment.paid) && (
                                <a
                                    className="btn btn__green full"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://app.youlab.nl/"
                                >
                                    Terug naar Youlab
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = createSelector(
    getPayment,
    getError,
    (payment, error) => ({ payment, error })
);

const mapDispatchToProps = {
    paymentOn,
    paymentOff,
    createPayment
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Payment)
);
