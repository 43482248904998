import React, { useEffect } from "react";

export const ModalEventHandler = ({ toggle }) => {
    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);

        return () => {
            removeEventHandler();
        };
    });

    const removeEventHandler = () => {
        document.removeEventListener("keyup", onKeyUp);
    };

    const onKeyUp = e => {
        if (e.keyCode === 27) {
            removeEventHandler();
            toggle();
        }
    };

    return <div></div>;
};
