import React from "react";

// - Libraries
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createSelector } from "reselect";

// - Firebase
import { getAuthenticated, getUserLoaded } from "../core/auth/selectors";

// Components
import Loader from "../views/pages/utils/page_loading";
import Authenticated from "../views/pages/authenticated";
import AppPayment from "./app_payments";

const App = ({ authenticated, loaded }) => {
    if (!authenticated)
        return (
            <Router>
                <AppPayment />
            </Router>
        );

    if (!loaded) return <Loader />;

    return (
        <Router>
            <Authenticated />
        </Router>
    );
};

const mapStateToProps = createSelector(
    getAuthenticated,
    getUserLoaded,
    (authenticated, loaded) => ({
        authenticated,
        loaded
    })
);

export default connect(mapStateToProps)(App);
