import React from "react";

// Components
import { ImageLoader } from "../../components/utils/image_loader";
import { Navigation } from "../../components/navigations/plain";

export const PageError = ({
    message = "No items found",
    slug = "youlab_started"
}) => (
    <main>
        <Navigation></Navigation>
        <section className="layout__minus_navigation row flex__reverse container">
            <div className="col__12 col_m__8 flex flex__center">
                <ImageLoader slug="empty_projects" className="full" />
            </div>
            <div className="col__12 col_m__4 flex flex__center">
                <div className="full center">
                    <h3 className="fw__n">{message}</h3>
                    <a href="https://app.youlab.nl" className="btn full mt__m">
                        Terug naar YouLab
                    </a>
                </div>
            </div>
        </section>
    </main>
);
