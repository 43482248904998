import { USER_AUTHENTICATED, USER_LOADED, USER_SIGN_OUT } from "./action-types";

import { Record } from "immutable";

import { User } from "../models";

const AuthState = new Record({
    authenticated: false,
    loaded: false,
    user: null
});

export function authenticationReducer(
    state = new AuthState(),
    { type, payload }
) {
    switch (type) {
        case USER_AUTHENTICATED:
            return state.setIn(["authenticated"], true);

        case USER_LOADED:
            return state.merge({
                loaded: true,
                user: new User(payload)
            });

        case USER_SIGN_OUT:
            return new AuthState();

        default:
            return state;
    }
}
