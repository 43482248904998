import React, { useEffect } from "react";

// - Libraries
import { withRouter } from "react-router-dom";

// Analytics
import { analytics } from "../../../core/firebase";

// - Variables
import { routes } from "../../../variables/routes_authentication";

// - Components
import { Navigation } from "../../components/navigations/plain";
import { SignIn } from "./sign_in";
import { ImageLoader } from "../../components/utils/image_loader";

const Authenticate = ({ history: { replace } }) => {
    useEffect(() => {
        return () => {
            replace(routes.ROOT);
        };
    }, [replace]);

    useEffect(() => {
        analytics.logEvent("enter_dashboard_login");
    }, []);

    return (
        <main>
            <Navigation></Navigation>
            <section className="layout__minus_navigation row flex__reverse container">
                <div className="col__12 col_m__8 flex flex__center">
                    <ImageLoader
                        slug="youlab_started"
                        className="img__payment"
                    />
                </div>
                <SignIn></SignIn>
            </section>
        </main>
    );
};

export default withRouter(Authenticate);
