import React from "react";

// - Components
import Social from "./social";

export const SignIn = () => (
    <div className="col__12 col_m__4 flex flex__align">
        <div>
            <h2 className="fw__n center">YouLab betalingen dashboard</h2>
            <Social />
        </div>
    </div>
);
