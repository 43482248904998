import { Record, List } from "immutable";

export const User = new Record({
    displayName: null,
    photoURL: null,
    email: null,
    uid: null
});

export const Group = new Record({
    id: null,

    name: null,
    organization: null,
    amount: null,
    created_by: null,
    created_at: null,
    visibility: true,

    users_size: 0,
    users_paid: 0,
    revenue: 0,
    notifications_submitted: 0
});

export const Payment = new Record({
    id: null,

    name: null,
    email: null,

    created_by: null,
    created_at: null,

    amount: null,

    groupId: null,

    paid: false,
    payment_link_created: false,
    payment_url: null,
    payment_id: null,
    payment_date: null,

    // Stats
    notifications_submitted: 0,
    notifications_frequency: "NONE"
});

export const Notification = new Record({
    id: null,

    created_at: null,
    submitted_at: null,
    type: null,
    success: false,
    submitted: false
});

export const unwrapSnapshot = (payload, Model) =>
    new Model({ ...payload.data(), id: payload.id });

export const unwrapList = (payload, Model) => {
    let list = [];

    payload.forEach(item => {
        list[list.length] = unwrapSnapshot(item, Model);
    });

    return new List(list);
};

export class CollectionListener {
    on(query, Model, dispatch, action) {
        const unsubscribe = query.onSnapshot(payload =>
            dispatch({
                type: action,
                payload: unwrapList(payload, Model)
            })
        );

        this._unsubscribe = () => unsubscribe();
    }

    off() {
        this._unsubscribe();
    }
}

export class DocumentListener {
    on(query, Model, dispatch, action, errorAction) {
        const unsubscribe = query.onSnapshot(payload =>
            payload.exists
                ? dispatch({
                      type: action,
                      payload: unwrapSnapshot(payload, Model)
                  })
                : dispatch({ type: errorAction })
        );

        this._unsubscribe = () => unsubscribe();
    }

    off() {
        this._unsubscribe();
    }
}
